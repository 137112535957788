#logo {
    filter: invert(1) hue-rotate(165deg) brightness(0.9);
    /*filter: chroma(5);*/

}
.app__navbar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--color-black);
    padding: 1rem 2rem;
}

.app__navbar-logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.app__navbar-logo img {
    width: 150px;
}

.app__navbar-links {
    flex: 1;
    justify-content: center;
    display: flex;
    align-items: center;

    list-style: none;
}

.app__navbar-links li {
    margin: 0 1rem;
    cursor: pointer;
    color: var(--color-white);
}

.app__navbar-links li:hover {
    border-bottom: 1px solid var(--color-golden);
    color: var(--color-crimson);
}

.app__navbar-contact {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.app__navbar-contact p {
    color: var(--color-white);
    margin: 0 1rem;
    text-decoration: none;
    transition: 0.5s ease;
}

.app__navbar-contact p:hover {
    border-bottom: 1px solid var(--color-golden);
    color: var(--color-crimson);
}

.app__navbar-smallScreen {
    display: none;
}

.app__navbar-smallScreen_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: var(--color-black);
    transition: 0.5s ease;
    flex-direction: column;
    z-index: 5;
}

.app__navbar-smallScreen_overlay .overlay__close {
    font-size: 27px;
    /*color: var(--color-golden);*/
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
}

.app__navbar-smallScreen_links {
    list-style: none;
}

.app__navbar-smallScreen_links li {
    color: var(--color-crimson);
    margin: 2rem;
    cursor: pointer;
    font-size: 2rem;
    text-align: center;
    font-family: var(--font-base);
}

.app__navbar-smallScreen_links li:hover {
    color: var(--color-golden);
}

@media screen and (max-width: 2000px){

}


@media screen and (max-width: 1150px){
    .app__navbar-links {
        display: none;
    }

    .app__navbar-smallScreen {
        display: flex;

    }
}

@media screen and (max-width: 650px){
    .app_navbar {
        padding: 1rem;
    }
    .app__navbar-logo img {
        width: 75px;
    }
}
